import getParticles from '@utils/getParticles'

const extractParticles = (src, { density, width, height, context }) => {
  return new Promise((resolve, reject) => {
    const image = new Image()
    image.onload = function () {
      context.clearRect(0, 0, width, height)
      context.drawImage(image, width / 2 - image.width / 2, height / 2 - image.height / 2)
      resolve(getParticles(context, { width, height, density }))
    }
    image.src = src
    image.onerror = function () {
      reject(new Error('cannot load image', src))
    }
  })
}

export default extractParticles
