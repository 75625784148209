import Touchable from '@components/Touchable/Touchable'
import animateScrollTo from 'animated-scroll-to'
import cx from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import * as styles from './Navigation.module.css'

const Navigation = ({ open, onRequestClose, className, ...rootProps }) => {
  const scrollToTop = () => {
    animateScrollTo(0)
    onRequestClose()
  }
  const scrollToServices = () => {
    animateScrollTo(document.getElementById('services'))
    onRequestClose()
  }
  const scrollToWork = () => {
    animateScrollTo(document.getElementById('work'))
    onRequestClose()
  }
  const scrollToContact = () => {
    animateScrollTo(document.getElementById('contact'))
    onRequestClose()
  }

  return (
    <div className={cx(styles.root, { [styles.open]: open }, className)} {...rootProps}>
      <div className={styles.container}>
        <ul className={styles.list}>
          <li className={styles.li}>
            <Touchable disableUnderline onClick={scrollToTop} className={styles.link}>
              about
            </Touchable>
          </li>
          <li className={styles.li}>
            <Touchable disableUnderline onClick={scrollToServices} className={styles.link}>
              services
            </Touchable>
          </li>
          <li className={styles.li}>
            <Touchable disableUnderline onClick={scrollToWork} className={styles.link}>
              work
            </Touchable>
          </li>
          <li className={styles.li}>
            <Touchable disableUnderline onClick={scrollToContact} className={styles.link}>
              contact
            </Touchable>
          </li>
        </ul>
      </div>
    </div>
  )
}

Navigation.propTypes = {
  className: PropTypes.string,
  onRequestClose: PropTypes.func,
}

Navigation.defaultProps = {
  onRequestClose: () => {},
}

export default Navigation
