function Particle(mx, my, fx, fy) {
  this.x = Math.random() > 0.5 ? mx : 0
  this.y = Math.random() > 0.5 ? my : 0
  this.mx = mx
  this.my = my
  this.fx = fx
  this.fy = fy
  this.vx = Math.random() - 0.5
  this.vy = Math.random() - 0.5
}

export default Particle
