import ScrollTo from '@components/ScrollTo/ScrollTo'
import Section from '@components/Section/Section'
import Text from '@components/Text/Text'
import Title from '@components/Title/Title'
import cx from 'classnames'
import * as React from 'react'
import * as styles from './Services.module.css'

import ServiceImageAgile from '@svg-components/services-agile.svg'
import ServiceImageComponents from '@svg-components/services-components.svg'
import ServiceImageEstimate from '@svg-components/services-estimate.svg'
import ServiceImageFrontend from '@svg-components/services-frontend.svg'
import ServiceImageLiaise from '@svg-components/services-liaise.svg'
// import ServiceImageManage from '@svg-components/services-manage.svg'
import PaddedBlock from '@components/PaddedBlock/PaddedBlock'
import ServiceImagePrototype from '@svg-components/services-prototype.svg'
import ServiceImageStyleguide from '@svg-components/services-styleguide.svg'
import ServiceImageTeam from '@svg-components/services-team.svg'
import ServiceImageUx from '@svg-components/services-ux.svg'

const services = [
  {
    label: 'Front-end web development',
    text: `We love working with react.js, the most used javascript framework on the internet`,
    image: <ServiceImageFrontend width={128} height={128} />,
  },
  {
    label: 'Component driven development',
    text: `We build basic components then progressively combine them to assemble screens`,
    image: <ServiceImageComponents width={128} height={128} />,
  },
  {
    label: 'Design System development',
    text: `We create your company DS or styleguide, with the support of your brand design team`,
    image: <ServiceImageStyleguide width={128} height={128} />,
  },
  {
    label: 'Prototypes, proof of concepts',
    text: `We test concepts, screens for you and make your ideas come true, quickly`,
    image: <ServiceImagePrototype width={128} height={128} />,
  },
  {
    label: 'Support during UX/UI design',
    text: `We offer technical support to design teams during initial brainstorming and design phases`,
    image: <ServiceImageUx width={128} height={128} />,
  },
  {
    label: `Agile, waterfall, scrum, lean`,
    text: `We work with different project management methodologies and easily adapt to new ones`,
    image: <ServiceImageAgile width={128} height={128} />,
  },
  {
    label: 'Liaise between different teams',
    text: `We handle the (usually-) missing link between the design and development teams`,
    image: <ServiceImageLiaise width={128} height={128} />,
  },
  {
    label: 'Feasibility and time estimates',
    text: `We offer advice on the viablity of features and estimate the time necessary to develop them`,
    image: <ServiceImageEstimate width={128} height={128} />,
  },
  // { label: 'Front-end project management', image: <ServiceImageManage width={128} height={128} /> },
  {
    label: 'Ability to scale as needed',
    text: `We have a network of freelance developers with whom we usually work`,
    image: <ServiceImageTeam width={128} height={128} />,
  },
]

const Services = ({ ready, ...props }) => {
  return (
    <Section id="services" className={styles.root} {...props}>
      <div className={styles.bg}>
        <PaddedBlock>
          <Title variant="h2" tag="h2" gutter="lg">
            What we do
          </Title>
          <div className={styles.services}>
            {services.map((service, idx) => {
              return (
                <section key={idx} className={styles.service}>
                  {service.image}
                  <Title variant="text" tag="h3" fontWeight="regular" gutter="sm" className={styles.shadow}>
                    {service.label}
                  </Title>
                  <Text className={cx(styles.text, styles.shadow)}>{service.text}</Text>
                </section>
              )
            })}
          </div>
          <ScrollTo id="work" />
        </PaddedBlock>
      </div>
    </Section>
  )
}

Services.propTypes = {}

export default Services
