import Touchable from '@components/Touchable/Touchable'
import animateScrollTo from 'animated-scroll-to'
import cx from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import * as styles from './ScrollTo.module.css'

const ScrollTo = ({ id, className, ...rootProps }) => {
  const scrollTo = () => {
    const element = document.getElementById(id)
    if (element) animateScrollTo(element)
  }
  return (
    <Touchable disableUnderline hoverBubble className={cx(styles.root, className)} onClick={scrollTo} {...rootProps}>
      <span className={styles.arrow} />
    </Touchable>
  )
}

ScrollTo.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
}

export default ScrollTo
