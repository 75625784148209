import Touchable from '@components/Touchable/Touchable'
import cx from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import * as styles from './Burger.module.css'

const Burger = ({ active, className, ...rootProps }) => {
  return (
    <Touchable
      disableUnderline
      hoverBubble
      className={cx(styles.root, { [styles.active]: active }, className)}
      {...rootProps}
    >
      <svg className={styles.svg} width="100" height="100" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
        <path className={styles.line1} d="M0 70l28-28c2-2 2-2 7-2h64" />
        <path className={styles.line2} d="M0 50h99" />
        <path className={styles.line3} d="M0 30l28 28c2 2 2 2 7 2h64" />
      </svg>
    </Touchable>
  )
}

Burger.propTypes = {
  className: PropTypes.string,
  active: PropTypes.bool,
}

export default Burger
