import cx from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import * as styles from './PaddedBlock.module.css'

const PaddedBlock = ({ verticalCenter, children, className, ...rootProps }) => {
  return (
    <div className={cx(styles.root, { [styles.verticalCenter]: verticalCenter }, className)} {...rootProps}>
      {children}
    </div>
  )
}

PaddedBlock.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  verticalCenter: PropTypes.bool,
}

export default PaddedBlock
