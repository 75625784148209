import cx from 'classnames'
import PropTypes from 'prop-types'
import * as React from 'react'
import * as styles from './Text.module.css'

const Text = ({ children, className, tag, fontWeight, gutter, ...rootProps }) => {
  const Tag = tag

  return (
    <Tag
      className={cx({
        [styles.root]: true,
        [styles[fontWeight]]: fontWeight,
        [styles[gutter]]: gutter,
        [className]: className,
      })}
      {...rootProps}
    >
      {children}
    </Tag>
  )
}

Text.propTypes = {
  className: PropTypes.string,
  fontWeight: PropTypes.oneOf(['light', 'regular', 'bold']),
  tag: PropTypes.oneOf(['p', 'span', 'div', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
  gutter: PropTypes.oneOf([null, 'sm', 'md', 'lg']),
}

Text.defaultProps = {
  fontWeight: 'regular',
  tag: 'p',
  gutter: null,
}

export default Text
