import Logotype from '@components/Logotype/Logotype'
import PaddedBlock from '@components/PaddedBlock/PaddedBlock'
import ScrollTo from '@components/ScrollTo/ScrollTo'
import Section from '@components/Section/Section'
import Text from '@components/Text/Text'
import Title from '@components/Title/Title'
import cx from 'classnames'
import * as React from 'react'
import * as styles from './Introduction.module.css'

const title = `Front-end development agency based in Luxembourg`
const subtitle = `We develop user friendly, modern web apps and websites`

const texts = [
  `We are a front-end web development company, building user interfaces for industry-leading brands and startups across Europe and worldwide.`,
  `We are dedicated to writing clean code and set up scalable and efficient front-end architectures, always empowering a great User eXperience.`,
  `We are passionate, fast learners, organized and adaptable.`,
]

const Introduction = ({ ready, ...rootProps }) => {
  const readyClassName = { [styles.ready]: ready }

  return (
    <Section id="introduction" className={styles.root} {...rootProps}>
      <PaddedBlock>
        <Title className={cx(styles.title, styles.shadow, readyClassName)} tag="h1" variant="h1" gutter="lg">
          {title}
        </Title>
        <Title
          className={cx(styles.subtitle, styles.shadow, readyClassName)}
          tag="h2"
          variant="h4"
          fontWeight="regular"
          gutter="lg"
        >
          {subtitle}
        </Title>
        {texts.map((text, idx) => (
          <Text
            tag={idx === 0 ? 'h3' : 'p'}
            key={idx}
            className={cx(styles.text, styles.shadow, readyClassName)}
            fontWeight="light"
            gutter={idx === texts.length - 1 ? 'lg' : 'md'}
          >
            {text}
          </Text>
        ))}
        <div className={cx(styles.final, readyClassName)}>
          <Title tag="h2" className={styles.finalTitle}>
            <span className={styles.weAre}>We are</span> <Logotype variant="dark" width={192} height={64} />
          </Title>
          <ScrollTo id="services" />
        </div>
      </PaddedBlock>
    </Section>
  )
}

Introduction.propTypes = {}

export default Introduction
