import extractParticles from '@utils/extractParticles'
import cx from 'classnames'
import { debounce } from 'debounce'
import PropTypes from 'prop-types'
import * as React from 'react'
import withSizes from 'react-sizes'
import * as styles from './Stage.module.css'

const animationFramesLimit = 2 * 60

class Stage extends React.PureComponent {
  constructor(props) {
    super(props)
    this.frame = 0
    this.image = require('@assets/luxembourg.svg').default
    this.stageCanvas = React.createRef()
    this.extractionCanvas = React.createRef()
    this.debouncedResizeHandler = debounce(this.initialize, 500)
  }
  componentDidMount() {
    if (window) {
      const setColors = () => {
        this.backgroundColor = window.getComputedStyle(document.body).getPropertyValue('--color-background--80')
        this.foregroundColorAlpha = window.getComputedStyle(document.body).getPropertyValue('--color-alpha--40')
        this.foregroundColorBravo = window.getComputedStyle(document.body).getPropertyValue('--color-bravo--80')
      }
      window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', setColors)
      setColors()
    }
    this.initialize()
  }
  componentDidUpdate = ({ width }) => {
    if (this.props.width > 768 && width !== this.props.width) {
      window.cancelAnimationFrame(this.animationFrameId)
      this.debouncedResizeHandler.clear()
      this.debouncedResizeHandler.flush()
      this.debouncedResizeHandler()
    }
  }
  initialize = () => {
    this.stageContext = this.stageCanvas.current?.getContext('2d')
    extractParticles(this.image, {
      density: this.props.width > 768 ? 4 : 10,
      width: this.props.width,
      height: this.props.height,
      context: this.extractionCanvas.current?.getContext('2d'),
    }).then((data) => {
      this.px = data
      this.animationFrameId = window.requestAnimationFrame(this.draw)
    })
  }
  draw = () => {
    this.frame = this.frame < animationFramesLimit ? this.frame + 1 : animationFramesLimit
    if (this.frame === animationFramesLimit) {
      this.props.onReady()
    }
    const { width, height } = this.props
    const closeEnough = 50
    this.stageContext.fillStyle = this.backgroundColor
    this.stageContext.fillRect(0, 0, width, height)
    for (let i = 0, len = this.px.length; i < len; i++) {
      if (this.px[i].x !== this.px[i].fx) {
        this.px[i].vx += Math.random() * 3 - 1.5
        this.px[i].x += this.px[i].vx
        if (this.px[i].x < this.px[i].fx + closeEnough && this.px[i].x > this.px[i].fx - closeEnough) {
          this.px[i].x = this.px[i].fx
        }
        if (this.px[i].x > this.px[i].fx + this.px[i].mx || this.px[i].x < this.px[i].fx - this.px[i].mx) {
          this.px[i].x = this.px[i].fx
        }
      }
      if (this.px[i].y !== this.px[i].fy) {
        this.px[i].vy += Math.random() * 3 - 1.5
        this.px[i].y += this.px[i].vy
        if (this.px[i].y < this.px[i].fy + closeEnough && this.px[i].y > this.px[i].fy - closeEnough) {
          this.px[i].y = this.px[i].fy
        }
        if (this.px[i].y > this.px[i].fy + this.px[i].my || this.px[i].y < this.px[i].fy - this.px[i].my) {
          this.px[i].y = this.px[i].fy
        }
      }
      const isHome = this.px[i].x === this.px[i].fx && this.px[i].y === this.px[i].fy
      this.stageContext.fillStyle = isHome ? this.foregroundColorAlpha : this.foregroundColorBravo
      this.stageContext.fillRect(this.px[i].x - 1, this.px[i].y - 1, 2, 2)
    }
    this.animationFrameId = window.requestAnimationFrame(this.draw)
  }
  render() {
    const { width, height, className, onReady, ...rootProps } = this.props
    return (
      <div className={cx(styles.root, className)} {...rootProps}>
        <canvas
          width={width}
          height={height}
          ref={this.extractionCanvas}
          className={cx(styles.fullPatateCanvas, styles.helper)}
        />
        <canvas
          width={width}
          height={height}
          ref={this.stageCanvas}
          className={cx(styles.fullPatateCanvas, styles.main)}
        />
      </div>
    )
  }
}

Stage.propTypes = {
  className: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  onReady: PropTypes.func,
}

const mapSizesToProps = ({ width, height }) => ({ width, height })

export default withSizes(mapSizesToProps)(Stage)
