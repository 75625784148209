import cx from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import * as styles from './Logotype.module.css'

const Logotype = ({ variant, className, ...rootProps }) => {
  return (
    <svg
      className={cx(styles.root, styles[variant], className)}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 595.28 140.11"
      width="595"
      height="140"
      aria-labelledby="title"
      {...rootProps}
    >
      <title id="title">reactive.lu</title>
      <path d="M72.77,75.21h0a17.13,17.13,0,0,0,3.34-6.12,23.22,23.22,0,0,0,1-6.7,19.27,19.27,0,0,0-1.49-7.82,15.47,15.47,0,0,0-4.29-5.71,20,20,0,0,0-6.65-3.56,27.77,27.77,0,0,0-8.49-1.24h-11A5.19,5.19,0,0,0,40,49.25V93.54h9.91V81.26h7.58l.46,0h0l12.49,12.3H82L68.31,78.79A15.69,15.69,0,0,0,72.77,75.21ZM67,62.7a10.38,10.38,0,0,1-.47,2.94A8.78,8.78,0,0,1,65,68.56,8,8,0,0,1,62,70.78a11.32,11.32,0,0,1-4.67.87H49.91V53.59h7.73a10.34,10.34,0,0,1,4.58.9,7.72,7.72,0,0,1,2.83,2.33,9.46,9.46,0,0,1,1.49,3A10.82,10.82,0,0,1,67,62.7Z" />
      <path d="M289.55,44.06H261.18a4.91,4.91,0,0,0-4.95,4.29A4.76,4.76,0,0,0,261,53.59h9.18V88.4a5.07,5.07,0,0,0,4.43,5.12,4.93,4.93,0,0,0,5.41-4.9v-35h9.35a4.9,4.9,0,0,0,5-4.29A4.76,4.76,0,0,0,289.55,44.06Z" />
      <path d="M313.06,44.08a5.11,5.11,0,0,0-4.46,5.16V88.36a5.1,5.1,0,0,0,4.46,5.15,5,5,0,0,0,5.45-4.93V49A5,5,0,0,0,313.06,44.08Z" />
      <path d="M168.24,44.06,145.76,86.7a4.64,4.64,0,0,0,.44,4.63,5.34,5.34,0,0,0,4.37,2.21,5.28,5.28,0,0,0,4.9-3.16L167.8,66.71l13,23.75a5.22,5.22,0,0,0,4.86,3.08h.15a5.34,5.34,0,0,0,4.37-2.2,4.64,4.64,0,0,0,.45-4.63Z" />
      <path d="M356.27,93.54,378.75,50.9a4.66,4.66,0,0,0-.44-4.64,5.4,5.4,0,0,0-9.26,1L356.72,70.88l-13-23.74a5.22,5.22,0,0,0-4.86-3.08h-.15a5.3,5.3,0,0,0-4.36,2.2,4.62,4.62,0,0,0-.45,4.63Z" />
      <path d="M133.92,88.29A4.92,4.92,0,0,0,129,84H105V73.91h14.86a4.91,4.91,0,0,0,5-4.29,4.77,4.77,0,0,0-4.74-5.24H105V53.59h22.91a4.91,4.91,0,0,0,5-4.29,4.78,4.78,0,0,0-4.74-5.25H95.06V93.54h34.11A4.78,4.78,0,0,0,133.92,88.29Z" />
      <path d="M432.38,88.29a4.92,4.92,0,0,0-5-4.29h-24V73.91H418.3a4.91,4.91,0,0,0,5-4.29,4.77,4.77,0,0,0-4.74-5.24H403.44V53.59h22.91a4.91,4.91,0,0,0,5-4.29,4.78,4.78,0,0,0-4.74-5.25H393.52V93.54h34.11A4.78,4.78,0,0,0,432.38,88.29Z" />
      <path d="M449.74,83.24a5,5,0,0,0-3.5,1.47,4.89,4.89,0,0,0-1.57,3.68,4.78,4.78,0,0,0,1.58,3.76,5,5,0,0,0,6.87,0,4.77,4.77,0,0,0,1.62-3.78A5.11,5.11,0,0,0,453,84.59,4.9,4.9,0,0,0,449.74,83.24Z" />
      <path d="M499.49,84H477.11V48.82a4.77,4.77,0,0,0-4.77-4.76H472a4.76,4.76,0,0,0-4.77,4.77V93.54H499.7a4.77,4.77,0,0,0,4.74-5.24A4.92,4.92,0,0,0,499.49,84Z" />
      <path d="M550,45.28a5,5,0,0,0-4.36,5v22A14.22,14.22,0,0,1,544,79a12.75,12.75,0,0,1-4.44,4.22,12.42,12.42,0,0,1-6.31,1.61,11.7,11.7,0,0,1-6.07-1.61A13,13,0,0,1,522.77,79a14.19,14.19,0,0,1-1.63-6.71V50.1a4.85,4.85,0,0,0-4.85-4.85h-.23a4.85,4.85,0,0,0-4.84,4.85V72.42c0,4.14,1,8.83,3,12a20.42,20.42,0,0,0,8,7.34,23.43,23.43,0,0,0,11.05,2.61,23.68,23.68,0,0,0,11.09-2.61,20.39,20.39,0,0,0,8-7.34c2-3.14,3-7.83,3-12V50.1A4.84,4.84,0,0,0,550,45.28Z" />
      <path d="M234.41,82.45c-9.43,4-21.58-3.13-21.61-14s12.18-18,21.61-14a5.1,5.1,0,0,0,6.08-1.51,5,5,0,0,0-1.85-7.61C223.18,38.5,203,50,202.8,67.93h0c0,.17,0,.34,0,.5s0,.33,0,.5h0c.18,17.94,20.38,29.44,35.84,22.63a5,5,0,0,0,1.85-7.6A5.09,5.09,0,0,0,234.41,82.45Z" />
    </svg>
  )
}

Logotype.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.oneOf(['colors', 'light', 'dark']),
}

Logotype.defaultProps = {
  variant: 'colors',
}

export default Logotype
