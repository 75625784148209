import PaddedBlock from '@components/PaddedBlock/PaddedBlock'
import Title from '@components/Title/Title'
import * as React from 'react'
import Marquee from 'react-fast-marquee'
import * as styles from './Work.module.css'

const brands = [
  { name: 'Publicis Groupe', logo: require('@assets/brands/publicisgroupe.svg').default },
  { name: 'Publicis Sapient', logo: require('@assets/brands/publicissapient.svg').default },
  { name: 'DigitasLBi France', logo: require('@assets/brands/digitas.png').default },
  { name: 'Lancôme', logo: require('@assets/brands/lancome.svg').default },
  { name: 'Nissan', logo: require('@assets/brands/nissan.svg').default },
  { name: 'LVMH', logo: require('@assets/brands/lvmh.svg').default },
  { name: 'Michelin', logo: require('@assets/brands/michelin.png').default },
  { name: 'Renault', logo: require('@assets/brands/renault.svg').default },
  { name: 'La Banque Postale', logo: require('@assets/brands/labanquepostale.svg').default },
  { name: 'La Poste', logo: require('@assets/brands/laposte.png').default },
  { name: 'EDF', logo: require('@assets/brands/edf.svg').default },
  { name: 'Nestlé', logo: require('@assets/brands/nestle.svg').default },
  { name: 'Bang & Olufsen', logo: require('@assets/brands/bangandolufsen.svg').default },
  { name: 'Longchamp', logo: require('@assets/brands/longchamp.svg').default },
  { name: 'Biogen', logo: require('@assets/brands/biogen.png').default },
  { name: 'Kayrros', logo: require('@assets/brands/kayrros.svg').default },
  { name: 'Heetch', logo: require('@assets/brands/heetch.png').default },
  { name: 'Ultrahuman', logo: require('@assets/brands/ultrahuman.png').default },
  { name: 'OncoDNA', logo: require('@assets/brands/oncodna.png').default },
]

const Work = ({ ready, ...props }) => {
  return (
    <section id="work" className={styles.root} {...props}>
      <div className={styles.wrapper}>
        <PaddedBlock verticalCenter className={styles.container}>
          <Title className={styles.visuallyHidden} tag="h2">
            Work
          </Title>
          <Title variant="h2" tag="p">
            Our developers have over 10 years of experience building front-end interfaces, during which they worked with
            some of Europe's most notable brands
          </Title>
        </PaddedBlock>
        <div className={styles.brands}>
          <Marquee gradient={false} speed={80}>
            {brands.map(({ name, logo }) => (
              <div key={name} className={styles.brand}>
                <img src={logo} alt={name} />
              </div>
            ))}
          </Marquee>
        </div>
      </div>
    </section>
  )
}

Work.propTypes = {}

export default Work
