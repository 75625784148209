import cx from 'classnames'
import PropTypes from 'prop-types'
import * as React from 'react'
import * as styles from './Title.module.css'

const Title = ({ children, className, tag, variant, gutter, fontWeight, ...rootProps }) => {
  const Tag = tag

  return (
    <Tag
      className={cx({
        [styles.root]: true,
        [styles[fontWeight]]: fontWeight,
        [styles[variant]]: variant,
        [styles[gutter]]: gutter,
        [className]: className,
      })}
      {...rootProps}
    >
      {children}
    </Tag>
  )
}

Title.propTypes = {
  className: PropTypes.string,
  fontWeight: PropTypes.oneOf(['light', 'regular', 'bold']),
  variant: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'text']),
  gutter: PropTypes.oneOf([null, 'sm', 'md', 'lg']),
}

Title.defaultProps = {
  fontWeight: 'bold',
  variant: 'h1',
  gutter: null,
}

export default Title
