// extracted by mini-css-extract-plugin
export var bold = "Title-module--bold--GnlLj";
export var h1 = "Title-module--h1--MkaXh";
export var h2 = "Title-module--h2--T8W2Z";
export var h3 = "Title-module--h3--XMK7f";
export var h4 = "Title-module--h4--sJCyR";
export var h5 = "Title-module--h5--dDg2k";
export var h6 = "Title-module--h6--PBHFw";
export var lg = "Title-module--lg--ITHsN";
export var light = "Title-module--light--Z4ign";
export var md = "Title-module--md--BGjql";
export var regular = "Title-module--regular--np2Cb";
export var root = "Title-module--root--7dkc6";
export var sm = "Title-module--sm--iX6YW";
export var text = "Title-module--text--qQddM";