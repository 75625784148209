import cx from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import * as styles from './Section.module.css'

const Section = ({ children, className, ...rootProps }) => {
  return (
    <section className={cx(styles.root, className)} {...rootProps}>
      {children}
    </section>
  )
}

Section.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
}

export default Section
