import useHover from '@hooks/useHover'
import { navigate } from '@reach/router'
import cx from 'classnames'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import * as React from 'react'
import * as styles from './Touchable.module.css'

const externalProps = {
  target: '_blank',
  rel: 'noopener noreferrer',
}

/**
 * This is the most basic represention of a button component
 */
const Touchable = ({
  children,
  className,
  disabled,
  external,
  onClick,
  tag,
  to,
  type,
  disableUnderline,
  hoverBubble,
  ...props
}) => {
  const [rootRef, isHovered] = useHover()

  const commonProps = {
    ...props,
    onClick,
    children: disableUnderline ? children : <span>{children}</span>,
    ref: rootRef,
  }

  commonProps.className = cx({
    [styles.root]: true,
    [styles.disableUnderline]: disableUnderline,
    [styles.hoverBubble]: hoverBubble,
    [className]: className,
  })

  if (disabled) {
    commonProps.tabIndex = -1
    commonProps.onClick = null
  }

  React.useEffect(() => {
    if (isHovered) {
      document.documentElement.setAttribute('data-cursor', 'action')
    } else {
      document.documentElement.setAttribute('data-cursor', 'default')
    }
  }, [isHovered])

  if (tag === 'a' || external) {
    // eslint-disable-next-line
    return <a href={to} {...commonProps} {...(external ? externalProps : {})} />
  }

  if (tag === 'button' || type) {
    return <button type={type} {...commonProps} />
  }

  if (!to) {
    return <button type="button" {...commonProps} />
  }

  return (
    <Link
      to={to}
      {...commonProps}
      onClick={(e) => {
        e.preventDefault()
        navigate(to)
        onClick && onClick(e)
      }}
    />
  )
}

Touchable.propTypes = {
  hoverBubble: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  disableUnderline: PropTypes.bool,
  external: PropTypes.bool,
  onClick: PropTypes.func,
  style: PropTypes.object,
  tag: PropTypes.oneOf(['a', 'button']),
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  type: PropTypes.oneOf(['button', 'submit']),
}

export default Touchable
