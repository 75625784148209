import cx from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import * as styles from './Logo.module.css'

const Logo = ({ variant, className, ...rootProps }) => {
  return (
    <svg
      className={cx(styles.root, styles[variant], className)}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 425.2 425.2"
      width="425.2"
      height="425.2"
      aria-labelledby="title"
      {...rootProps}
    >
      <title id="title">reactive.lu</title>
      <path
        className={styles.left}
        d="M213,72.59,339.46,314a26.3,26.3,0,0,1-2.55,26.21,30.21,30.21,0,0,1-24.73,12.45h-.85c-12.16,0-23-6.85-27.5-17.44L210.49,200.83l-32.77-61.4Z"
      />
      <path
        className={styles.right}
        d="M177.72,139.43l32.77,61.4L140.71,334.78c-4.36,10.64-15.51,17.83-27.73,17.83a30.23,30.23,0,0,1-24.73-12.45,26.37,26.37,0,0,1-2.49-26.26Z"
      />
    </svg>
  )
}

Logo.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.oneOf(['colors', 'light', 'dark']),
}

Logo.defaultProps = {
  variant: 'colors',
}

export default Logo
