import Logo from '@components/Logo/Logo'
import PaddedBlock from '@components/PaddedBlock/PaddedBlock'
import Section from '@components/Section/Section'
import Text from '@components/Text/Text'
import Title from '@components/Title/Title'
import Touchable from '@components/Touchable/Touchable'
import cx from 'classnames'
import * as React from 'react'
import * as styles from './Contact.module.css'

const Contact = (props) => {
  return (
    <Section id="contact" className={styles.root} {...props}>
      <PaddedBlock>
        <Title variant="h2" tag="h2" gutter="lg">
          Let's get in touch
        </Title>
        <Text gutter="md" className={styles.shadow}>
          <Touchable external to="mailto:hello@reactive.lu">
            hello@reactive.lu
          </Touchable>
        </Text>
        <Text gutter="lg" className={styles.shadow}>
          <Touchable external to="tel:+352691324004">
            +352 691 32 40 04
          </Touchable>
        </Text>
        <Logo variant="dark" width={250} height={250} className={styles.logo} />
        <footer>
          <Text className={cx(styles.legal, styles.shadow)}>
            REACTIVE.LU S.À.R.L. ● 10A RUE DU PUITS, L-2355 LUXEMBOURG
          </Text>
        </footer>
      </PaddedBlock>
    </Section>
  )
}

Contact.propTypes = {}

export default Contact
