import Header from '@components/Header/Header'
import Seo from '@components/Seo/Seo'
import Contact from '@containers/Contact/Contact'
import Introduction from '@containers/Introduction/Introduction'
import Services from '@containers/Services/Services'
import Stage from '@containers/Stage/Stage'
import Work from '@containers/Work/Work'
import * as React from 'react'

const IndexPage = () => {
  const [loaded, setLoaded] = React.useState(false)
  const [ready, setReady] = React.useState(false)
  const webfontLoaded = () => setLoaded(true)

  const webfontConfig = {
    google: { families: ['Montserrat:300,500,700'] },
    timeout: 3000,
    classes: false,
    active: webfontLoaded,
    inactive: webfontLoaded,
  }

  React.useEffect(() => {
    require('webfontloader').load(webfontConfig)
    if (typeof window !== 'undefined' && 'serviceWorker' in navigator) {
      window.navigator.serviceWorker.getRegistrations().then((registrations) => {
        registrations.forEach((r) => r.unregister())
      })
    }
  })

  return loaded ? (
    <React.Fragment>
      <Header />
      <Stage onReady={() => setReady(true)} />
      <Introduction ready={ready} />
      <Services />
      <Work />
      <Contact />
    </React.Fragment>
  ) : (
    <noscript>
      <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@300;500;700&display=swap" rel="stylesheet" />
      <Introduction ready />
      <Services />
      <Work />
      <Contact />
    </noscript>
  )
}

export default IndexPage

export const Head = () => <Seo />
