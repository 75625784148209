import Burger from '@components/Burger/Burger'
import Logo from '@components/Logo/Logo'
import Navigation from '@components/Navigation/Navigation'
import Touchable from '@components/Touchable/Touchable'
import animateScrollTo from 'animated-scroll-to'
import cx from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import * as styles from './Header.module.css'

const Header = ({ className, ...rootProps }) => {
  const [open, setOpen] = React.useState(false)

  return (
    <header className={cx(styles.root, className)} {...rootProps}>
      <Touchable
        className={styles.logo}
        disableUnderline
        hoverBubble
        external
        to="https://reactive.lu"
        onClick={(e) => {
          e.preventDefault()
          animateScrollTo(0)
        }}
      >
        <Logo variant="colors" width={64} height={64} />
      </Touchable>
      <Burger className={styles.burger} active={open} onClick={() => setOpen(!open)} />
      <Navigation open={open} onRequestClose={() => setOpen(false)} />
    </header>
  )
}

Header.propTypes = {
  className: PropTypes.string,
}

export default Header
